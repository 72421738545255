
const UNDEFINED = 'undefined'

export const KEYS = {
  USER: () => ['user'],
  AUTHENTICATED: () => ['authenticated'],
  BRANDING: () => ['branding'],
  BRANDING_CNAME: (cname) => ['branding', cname],

  VENDOR: {
    PARTICIPANT_INFO: () => ['participantInfo'],
    COMPANIES: () => ['companies'],
    COMPANIES_BRANDING: () => ['companyBranding'],
    LISTINGS: () => ['vendor', 'listings'],
    LISTING: (listingId = UNDEFINED) => [
      'vendor',
      'listings',
      (listingId + '').toString()
    ],
    LISTING_LOTS: (listingId = UNDEFINED) => [
      'vendor',
      'listings',
      (listingId + '').toString(),
      'lot'
    ],
    LISTING_LOT: (listingId = UNDEFINED, listingLotId = UNDEFINED) => [
      'vendor',
      'listings',
      (listingId + '').toString(),
      'lot',
      (listingLotId + '').toString()
    ],
    LISTING_LOT_SKUS: (listingId = UNDEFINED, listingLotId = UNDEFINED) => {
      return [
        'vendor',
        'listings',
        (listingId + '').toString(),
        'lot',
        (listingLotId + '').toString(),
        'skus'
      ]
    },
    LISTING_LOT_IMEIS: (listingId = UNDEFINED, listingLotIds = UNDEFINED) => {
      if (typeof listingLotIds === 'string') listingLotIds = [listingLotIds]
      if (Array.isArray(listingLotIds)) {
        return [
          'vendor',
          'listings',
          (listingId + '').toString(),
          'lot',
          listingLotIds.map(id => (id + '').toString()),
          'imeis'
        ]
      } else {
        return [
          'vendor',
          'listings',
          (listingId + '').toString()
        ]
      }
    }
  },

  BUYER: {
    LISTINGS: () => ['buyer', 'listings'],
    LISTING: (listingId = UNDEFINED) => [
      'buyer',
      'listings',
      (listingId + '').toString()
    ],
    LISTING_LOTS: (listingId = UNDEFINED) => [
      'buyer',
      'listings',
      (listingId + '').toString(),
      'lot'
    ],
    LISTING_LOTS_BULK: (listingId = UNDEFINED, listingLotIds = []) => {
      if (typeof listingLotIds === 'string') listingLotIds = [listingLotIds]
      if (Array.isArray(listingLotIds)) {
        return [
          'buyer',
          'listings',
          (listingId + '').toString(),
          'lot',
          listingLotIds.map(id => (id + '').toString())
        ]
      } else {
        return [
          'buyer',
          'listings',
          (listingId + '').toString()
        ]
      }
    },
    LISTING_LOT: (listingId = UNDEFINED, listingLotId = UNDEFINED) => [
      'buyer',
      'listings',
      (listingId + '').toString(),
      'lot',
      (listingLotId + '').toString()
    ],
    LISTING_LOT_SKUS: (listingId = UNDEFINED, listingLotId = UNDEFINED) => {
      return [
        'buyer',
        'listings',
        (listingId + '').toString(),
        'lot',
        (listingLotId + '').toString(),
        'skus'
      ]
    },
    LISTING_LOT_IMEIS: (listingId = UNDEFINED, listingLotIds = []) => {
      if (typeof listingLotIds === 'string') listingLotIds = [listingLotIds]
      if (Array.isArray(listingLotIds)) {
        return [
          'buyer',
          'listings',
          (listingId + '').toString(),
          'lot',
          listingLotIds.map(id => (id + '').toString()),
          'imeis'
        ]
      } else {
        return [
          'buyer',
          'listings',
          (listingId + '').toString()
        ]
      }
    }
  }
}

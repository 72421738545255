const { navigate } = require('gatsby-link')
const querystring = require('querystring')

const isBrowser = typeof window !== 'undefined'

const verify = (str, context) => {
  if (typeof str === 'undefined' || str === null) throw new Error(`${context} - value '${str}' must be a valid object`)
}

const vendorNavTo = {
  listingsPage: () => {
    navigate('/app/listings/listVendor')
  },
  listingsDetailsPage: (listingId) => {
    verify(listingId, 'listingId')
    navigate(`/app/listings/${listingId}/viewVendor`)
  },
  productManifestPage: (listingId) => {
    verify(listingId, 'listingId')
    navigate(`/app/listings/${listingId}/viewVendorProductManifest`)
  },
  editListingPage: (listingId, state = {}) => {
    navigate(`/app/listings/${listingId}/edit`, { state })
  }
}
const buyerNavTo = {
  listingsDetailsPage: (listingId) => {
    verify(listingId, 'listingId')
    navigate(`/app/listings/${listingId}/viewBuyer`)
  },
  productManifestPage: (listingId) => {
    verify(listingId, 'listingId')
    navigate(`/app/listings/${listingId}/viewBuyerProductManifest`)
  },
  submitUnitPricesPage: (listingId, listingLotId) => {
    verify(listingId, 'listingId')
    verify(listingLotId, 'listingLotId')
    navigate(`/app/listings/${listingId}/lots/${listingLotId}/submitUnitPricesBuyer`)
  }
}

/**
 * Navigation routes for unauthorised (or any) users.
 */
const unauthNavTo = {
  loginPage: () => {
    console.log('Navigating to login page...')
    navigate('/login')
  },
  forgotPassword: (state) => navigate('/forgotPassword', { state }),
  forgotPasswordComplete: (state) => navigate('/forgotPasswordComplete', { state }),
  resetPassword: () => navigate('/resetPassword'),
  resetPasswordComplete: () => navigate('/resetPasswordComplete'),
  dashboardPage: () => navigate('/app/dashboard'),
  registerAccountWithToken: (state) => {
    const queryParams = {}
    if (state) {
      queryParams.participant = state.participant
      queryParams.token = state.token
      queryParams.email = state.email
      if (state.newUserInvitee) queryParams.company = state.newUserInvitee
      if (state.member) queryParams.member = state.member
    }
    navigate('/signup/registerNewAccount?' + querystring.stringify(queryParams), { state })
  },
  tokenNotValid: (state) => navigate('/signup/tokenNotValid'),
  viewParticipantPage: (state) => navigate('/app/accounts/' + state + '/viewCompany'),
  viewParticipantList: () => navigate('/app/accounts/participants'),
  myAccount: () => navigate('/app/accounts/myAccount'),
  registerNow: () => window.open('https://forms.reform.app/g0Cqzj/registration/09671f')
}

const hardNavTo = {
  loginPage: () => { if (isBrowser) window.location.href = '/' }
}

module.exports = {
  vendorNavTo,
  buyerNavTo,
  unauthNavTo,
  hardNavTo
}

import firebase from 'firebase/app'
import 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'

// Niss DEV
// eslint-disable-next-line no-unused-vars
const firebaseConfigDev = {
  apiKey: 'AIzaSyCKquFO_nbppL4OwiDhPxb2w6LvNlH4Ezk',
  authDomain: 'reflowhub-4f5c6.firebaseapp.com',
  projectId: 'reflowhub-4f5c6',
  storageBucket: 'reflowhub-4f5c6.appspot.com',
  messagingSenderId: '380266114448',
  appId: '1:380266114448:web:e7fc7ebf896c9dc947d715',
  measurementId: 'G-16DPCQRHRG'
}

// MP SANDBOX
// eslint-disable-next-line no-unused-vars
const firebaseConfigSandbox = {
  apiKey: 'AIzaSyAftnQNCsn2G8w5DBuPFnxwXfXBqJnGITw',
  authDomain: 'reflowhub-sandbox.firebaseapp.com',
  projectId: 'reflowhub-sandbox',
  storageBucket: 'reflowhub-sandbox.appspot.com',
  messagingSenderId: '148240431355',
  appId: '1:148240431355:web:2bff246923630ea85ad85e',
  measurementId: 'G-RDLLLL7WC2'
}

// eslint-disable-next-line no-unused-vars
const firebaseConfigProd = {
  apiKey: 'AIzaSyDvmdwMlulGDwdf1Te7ggh-r5CcPiJP-eI',
  authDomain: 'reflowhub-prod.firebaseapp.com',
  projectId: 'reflowhub-prod',
  storageBucket: 'reflowhub-prod.appspot.com',
  messagingSenderId: '598669265332',
  appId: '1:598669265332:web:9a030fbba852fa7450cae1',
  measurementId: 'G-Z2EGW7BRKH'
}

// Toggle current Firebase environment here
export const firebaseEnv = firebaseConfigDev

const MOCK_FIREBASE_FOR_BUILD = { auth: () => {} }

const isBrowser = () => typeof window !== 'undefined'

export const app = firebase.initializeApp(firebaseEnv)

export const auth = () => {
  return isBrowser() ? app.auth() : MOCK_FIREBASE_FOR_BUILD
}
// export const auth = () => firebase.auth()

export const useIsAuthenticatedHook = () => {
  return useAuthState(auth())
}

export default { app, auth }
